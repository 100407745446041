var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n  class='z-50 sticky right-0 bottom-0 w-full pb-10 pr-10 pointer-events-none'\n  data-cy='toast'\n>\n  <div\n    x-data=\"{'auto_hide': "
    + alias4(((helper = (helper = lookupProperty(helpers,"toast_auto_hide") || (depth0 != null ? lookupProperty(depth0,"toast_auto_hide") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"toast_auto_hide","hash":{},"data":data,"loc":{"start":{"line":6,"column":26},"end":{"line":6,"column":45}}}) : helper)))
    + " }\"\n    x-init='if(auto_hide) setTimeout(() => $el.remove(), 2500)'\n    id='"
    + alias4(((helper = (helper = lookupProperty(helpers,"toast_id") || (depth0 != null ? lookupProperty(depth0,"toast_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"toast_id","hash":{},"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":8,"column":20}}}) : helper)))
    + "'\n    class='shadow-lg bg-white max-w-md ml-auto'\n    role='alert'\n  >\n\n    <div class='bg-elsred text-white px-5 py-1 h-9'>\n      <strong class='align-middle' x-text='_(\""
    + alias4(((helper = (helper = lookupProperty(helpers,"toast_title") || (depth0 != null ? lookupProperty(depth0,"toast_title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"toast_title","hash":{},"data":data,"loc":{"start":{"line":14,"column":46},"end":{"line":14,"column":61}}}) : helper)))
    + "\")'></strong>\n      <button\n        data-cy='close_toast_button'\n        class='absolute right-12 w-5 top-2 h-5 fa-solid fa-xmark pointer-events-auto'\n        @click=\"document.getElementById('"
    + alias4(((helper = (helper = lookupProperty(helpers,"toast_id") || (depth0 != null ? lookupProperty(depth0,"toast_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"toast_id","hash":{},"data":data,"loc":{"start":{"line":18,"column":41},"end":{"line":18,"column":53}}}) : helper)))
    + "')?.remove()\"\n        type='button'\n      >\n      </button>\n    </div>\n    <div\n      data-cy='toast_message'\n      class='p-5 overflow-y-auto max-h-32 pointer-events-auto'\n    >"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"toast_message") || (depth0 != null ? lookupProperty(depth0,"toast_message") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"toast_message","hash":{},"data":data,"loc":{"start":{"line":26,"column":5},"end":{"line":26,"column":24}}}) : helper))) != null ? stack1 : "")
    + "</div>\n  </div>\n</div>";
},"useData":true});
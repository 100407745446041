var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    x-init='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"model_value") : depth0), depth0))
    + " = current_option.get_input_value()'\n    x-model='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"model_value") : depth0), depth0))
    + "'\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    :value='current_option.get_input_value()'\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n  x-data='{current_option:{}}'\n  x-init='current_option = get_controller(\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"current_option_uuid") : depth0), depth0))
    + "\")'\n  :id='\"edit_\" + current_option.uid'\n  class='w-full pl-1'\n>\n  <input\n    :placeholder=\"_(current_option.name)\"\n    :name=\"current_option.handle\"\n    :id='current_option.uid + \"_input\"'\n    :class='get_text_input_classes()'\n    x-on:input.change='!ignore_changes && current_option.update_history()'\n    x-on:change='!ignore_changes && current_option.update_history()'\n    class='w-full invalid:bg-amber-100'\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"model_value") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":20,"column":11}}})) != null ? stack1 : "")
    + "    type='text'\n    x-on:keyup.enter='current_option.input_element_enterkey()'\n    :data-cy='current_option.handle + \"_input\"'\n    @click='current_option.conf.name_comment()'\n    x-on:input='current_option.conf.name_comment()'\n    x-bind:required='current_option.is_required'\n  />\n"
    + ((stack1 = container.invokePartial(require("./close_edit_option_buttons.handlebars"),depth0,{"name":"close_edit_option_buttons","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true});
var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id='update-pw_div' class='min-h-full mb-2' x-show='manage_userinfo_open'>\n  <div>\n    <form class='mt-2' action='javascript:get_controller(\""
    + alias4(((helper = (helper = lookupProperty(helpers,"conf_uuid") || (depth0 != null ? lookupProperty(depth0,"conf_uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"conf_uuid","hash":{},"data":data,"loc":{"start":{"line":3,"column":58},"end":{"line":3,"column":71}}}) : helper)))
    + "\").confirm_userinfo_update()'>\n      <div class='pb-2'>\n        <label x-text=\"_('Email address')\" for='update_current_email' class='table-cell pr-6'></label>\n        <input\n          id='update_current_email'\n          type='email'\n          autocomplete='off'\n          value='"
    + alias4(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":10,"column":17},"end":{"line":10,"column":26}}}) : helper)))
    + "'\n          :class='get_text_input_classes()'\n          class=\"invalid:bg-red-200\"\n          required\n        />\n      </div>\n      <div class='pb-2'>\n        <label x-text=\"_('New password')\" for='update_new_pw' class='table-cell pr-6'></label>\n        <input\n          id='update_new_pw'\n          type='password'\n          autocomplete='new-password'\n          :class='get_text_input_classes()'\n          class=\"invalid:bg-red-200\"\n          pattern='^.{10}.*$'\n          x-on:invalid=\"$el.setCustomValidity('Invalid password, must be at least 8 characters long and contain an uppercase and a number')\"\n        />\n      </div>\n      <div class='pb-2'>\n        <label x-text=\"_('Confirm new password')\" for='update_confirm_pw' class='table-cell pr-6'></label>\n        <input\n          id='update_confirm_pw'\n          type='password'\n          autocomplete='new-password'\n          x-on:input=\"get_controller('"
    + alias4(((helper = (helper = lookupProperty(helpers,"conf_uuid") || (depth0 != null ? lookupProperty(depth0,"conf_uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"conf_uuid","hash":{},"data":data,"loc":{"start":{"line":34,"column":38},"end":{"line":34,"column":51}}}) : helper)))
    + "').validate_confirm_pw($el)\"\n          :class='get_text_input_classes()'\n          class=\"invalid:bg-red-200\"\n        />\n      </div>\n      <div class='pb-2'>\n        <label x-text=\"_('Current password')\" for='update_current_pw' class='table-cell pr-6'></label>\n        <input\n          id='update_current_pw'\n          type='password'\n          autocomplete='new-password'\n          :class='get_text_input_classes()'\n          class=\"invalid:bg-red-200\"\n          required\n        />\n      </div>\n      <div class='mt-6'>\n        <button id=\"update_pw_button\" :class='get_filled_button_classes()' type='submit' x-text='_(\"Update password\")'></button>\n        <button :class='get_outline_button_classes()' type='reset' x-text='_(\"Reset value\")'></button>\n      </div>\n    </form>\n  </div>\n</div>";
},"useData":true});
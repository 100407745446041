var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='space-y-2'>\n  <p>\n    <label x-text=\"_('Label')\" for='new_markpoint_title'></label>\n    <input id='new_markpoint_title' type='text' value="
    + alias4(((helper = (helper = lookupProperty(helpers,"old_value") || (depth0 != null ? lookupProperty(depth0,"old_value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"old_value","hash":{},"data":data,"loc":{"start":{"line":4,"column":54},"end":{"line":4,"column":67}}}) : helper)))
    + " :class='get_text_input_classes' maxlength='2' />\n  </p>\n  <p>\n    <label x-text=\"_('Description')\" for='new_markpoint_value'></label>\n    <input id='new_markpoint_value' type='text' value='"
    + alias4(((helper = (helper = lookupProperty(helpers,"old_legend") || (depth0 != null ? lookupProperty(depth0,"old_legend") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"old_legend","hash":{},"data":data,"loc":{"start":{"line":8,"column":55},"end":{"line":8,"column":69}}}) : helper)))
    + "' :class='get_text_input_classes' />\n  </p>\n</div>";
},"useData":true});
var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<nav id='navbar' class='bg-elsred w-full z-20'>\n  <div class='mx-auto px-2 sm:px-6 lg:px-8'>\n    <div id='navbar_main_div' class='relative flex h-12 items-center justify-between'>\n      <div id='cg_home_btn' class='flex pr-2 items-center'>\n        <div data-cy='go-home' @click='show_home($event)' class='grid grid-rows-1 grid-flow-col cursor-pointer'>\n          <img id='app-icon' class='w-auto max-h-10 py-2' alt='Ceramics Genome' />\n          <span class='font-bold px-2 whitespace-nowrap self-center'>Ceramics Genome</span>\n        </div>\n        <div class='h-full items-center'>\n          <span class='relative mt-1 mr-2' x-data='{}'>\n            <input\n              x-model='fast_search_value'\n              type='text'\n              name='home_search'\n              id='search_in_home'\n              class='max-w-lg px-2'\n              :class='[...get_text_input_classes(), \"disabled:hidden\"]'\n              :placeholder='_(\"find document...\")'\n              autocomplete='off'\n              @input.debounce='$dispatch(current_search_event); $dispatch(\"show-search-results\")'\n              data-cy='fast_search'\n            />\n          </span>\n        </div>\n        <div id='left-navbar-menus' class='flex h-full items-center'>\n"
    + ((stack1 = container.invokePartial(require("./genome_navbar.handlebars"),depth0,{"name":"genome_navbar","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n      </div>\n      <div id='navbar-right-div' class='right-0 flex'>\n"
    + ((stack1 = container.invokePartial(require("./navbar_utils_menu.handlebars"),depth0,{"name":"navbar_utils_menu","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n    </div>\n  </div>\n  <div\n    id='newDocument_menu'\n    class='hidden'\n    @report-menu.window='newMaterial_menu=false;newAnalysis_menu=false;newMisc_menu=false;newModel_menu=true'\n    @genome-menu.window='newMaterial_menu=true;newAnalysis_menu=false;newMisc_menu=false;newModel_menu=false'\n  >\n    <div id='newMaterial_nav' class='bg-red-800' x-show='newMaterial_menu'>\n      <div id='newMaterial_list' class='list-none inline'>\n"
    + ((stack1 = container.invokePartial(require("./new_material.handlebars"),depth0,{"name":"new_material","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n    </div>\n    <div id='newAnalysis_nav' class='bg-red-800' x-show='newAnalysis_menu'>\n      <div id='newAnalysis_list' class='list-none inline'>\n"
    + ((stack1 = container.invokePartial(require("./new_analysis.handlebars"),depth0,{"name":"new_analysis","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n    </div>\n    <div id='newMisc_nav' class='bg-red-800' x-show='newMisc_menu'>\n      <div id='newMisc_list' class='list-none inline'>\n"
    + ((stack1 = container.invokePartial(require("./new_misc.handlebars"),depth0,{"name":"new_misc","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n    </div>\n    <div id='newModel_nav' class='bg-red-800' x-show='newModel_menu'>\n      <div id='newModel_list' class='list-none inline'>\n"
    + ((stack1 = container.invokePartial(require("./new_model.handlebars"),depth0,{"name":"new_model","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n    </div>\n  </div>\n</nav>";
},"usePartial":true,"useData":true});
var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class='px-2 sm:px-6 lg:px-8'>\n"
    + ((stack1 = container.invokePartial(require("./navbar_anchors.handlebars"),depth0,{"name":"navbar_anchors","hash":{"doc_type":"Agent","button_text":"Agent"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./navbar_anchors.handlebars"),depth0,{"name":"navbar_anchors","hash":{"doc_type":"Instrument","button_text":"Instrument"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./navbar_anchors.handlebars"),depth0,{"name":"navbar_anchors","hash":{"doc_type":"Company","button_text":"Company"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./navbar_anchors.handlebars"),depth0,{"name":"navbar_anchors","hash":{"doc_type":"Logistic","button_text":"Logistic"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./navbar_anchors.handlebars"),depth0,{"name":"navbar_anchors","hash":{"doc_type":"Place","button_text":"Place"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./navbar_anchors.handlebars"),depth0,{"name":"navbar_anchors","hash":{"doc_type":"UseCase","button_text":"Use Case"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./navbar_anchors.handlebars"),depth0,{"name":"navbar_anchors","hash":{"doc_type":"CustomLabel","button_text":"Custom Label"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./navbar_anchors.handlebars"),depth0,{"name":"navbar_anchors","hash":{"doc_type":"PreparationMethod","button_text":"Preparation Method"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./navbar_anchors.handlebars"),depth0,{"name":"navbar_anchors","hash":{"doc_type":"PhysicalForm","button_text":"Physical Form"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./navbar_anchors.handlebars"),depth0,{"name":"navbar_anchors","hash":{"doc_type":"Tag","button_text":"Tag"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./navbar_anchors.handlebars"),depth0,{"name":"navbar_anchors","hash":{"doc_type":"FieldTabular","button_text":"Tabular Field"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./navbar_anchors.handlebars"),depth0,{"name":"navbar_anchors","hash":{"doc_type":"FieldEquation","button_text":"Equation Field"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./navbar_anchors.handlebars"),depth0,{"name":"navbar_anchors","hash":{"doc_type":"FieldCompositionEquation","button_text":"Composition Field"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true});
var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <option\n          x-text=\"_('"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0))
    + "')\"\n          class='mChooser_materials'\n          :class=\"{'font-bold': "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"option_checked") : depth0), depth0))
    + ", '' : !"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"option_checked") : depth0), depth0))
    + " }\"\n          value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "'\n          data-cy='option_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "'\n        >\n\n        </option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <option\n          x-text=\"_('"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0))
    + "')\"\n          class='mChooser_analysis'\n          :class=\"{'font-bold': "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"option_checked") : depth0), depth0))
    + ", '' : !"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"option_checked") : depth0), depth0))
    + " }\"\n          value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "'\n        >\n        </option>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <option\n          x-text=\"_('"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0))
    + "')\"\n          class='mChooser_others'\n          :class=\"{'font-bold': "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"option_checked") : depth0), depth0))
    + ", '' : !"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"option_checked") : depth0), depth0))
    + " }\"\n          value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "'\n        >\n        </option>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <option\n          x-text=\"_('"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0))
    + "')\"\n          class='mChooser_reports'\n          :class=\"{'font-bold': "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"option_checked") : depth0), depth0))
    + ", '' : !"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"option_checked") : depth0), depth0))
    + " }\"\n          value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "'\n        >\n        </option>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <option\n          x-text=\"_('"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0))
    + "')\"\n          class='mChooser_models'\n          :class=\"{'font-bold': "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"option_checked") : depth0), depth0))
    + ", '' : !"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"option_checked") : depth0), depth0))
    + " }\"\n          value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "'\n        >\n        </option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n  x-data='{current_option:{}}'\n  x-init='current_option = get_controller(\""
    + alias4(((helper = (helper = lookupProperty(helpers,"current_option_uuid") || (depth0 != null ? lookupProperty(depth0,"current_option_uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"current_option_uuid","hash":{},"data":data,"loc":{"start":{"line":3,"column":43},"end":{"line":3,"column":66}}}) : helper)))
    + "\")'\n  :id='current_option.uid'\n>\n  <select\n    :id='current_option.uid + \"_input\"'\n    class='rounded-sm py-0 focus:!ring-elsred focus:!border-elsred'\n    x-on:change='current_option.multichooser_on_change($event.target)'\n    :title='current_option.get_tooltip()'\n    :data-cy='current_option.handle + \"_input\"'\n  >\n    <option\n      :id='current_option.handle + \"_default_option\"'\n      selected\n      hidden\n      disabled\n      x-text=\"_('Select entries')\"\n    ></option>\n    <optgroup :label='_(\"Materials\")' x-show=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"show_genome") || (depth0 != null ? lookupProperty(depth0,"show_genome") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"show_genome","hash":{},"data":data,"loc":{"start":{"line":20,"column":46},"end":{"line":20,"column":61}}}) : helper)))
    + "\">\n      <option x-text=\"_('Select all')\" value='all_materials_select' class='italic'>\n      </option>\n      <option x-text=\"_('Select none')\" value='none_materials_select' class='italic'>\n      </option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"materials") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":6},"end":{"line":35,"column":15}}})) != null ? stack1 : "")
    + "    </optgroup>\n    <optgroup :label='_(\"Analysis\")' x-show=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"show_genome") || (depth0 != null ? lookupProperty(depth0,"show_genome") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"show_genome","hash":{},"data":data,"loc":{"start":{"line":37,"column":45},"end":{"line":37,"column":60}}}) : helper)))
    + "\">\n      <option x-text=\"_('Select all')\" value='all_analysis_select' class='italic'>\n      </option>\n      <option x-text=\"_('Select none')\" value='none_analysis_select' class='italic'>\n      </option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"analysis") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":6},"end":{"line":50,"column":15}}})) != null ? stack1 : "")
    + "    </optgroup>\n    <optgroup :label='_(\"Other\")' x-show=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"show_genome") || (depth0 != null ? lookupProperty(depth0,"show_genome") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"show_genome","hash":{},"data":data,"loc":{"start":{"line":52,"column":42},"end":{"line":52,"column":57}}}) : helper)))
    + "\">\n      <option x-text=\"_('Select all')\" value='all_others_select' class='italic'>\n      </option>\n      <option x-text=\"_('Select none')\" value='none_others_select' class='italic'>\n      </option>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"others") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":6},"end":{"line":66,"column":15}}})) != null ? stack1 : "")
    + "    </optgroup>\n    <optgroup :label='_(\"Reports\")' x-show=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"show_report") || (depth0 != null ? lookupProperty(depth0,"show_report") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"show_report","hash":{},"data":data,"loc":{"start":{"line":68,"column":44},"end":{"line":68,"column":59}}}) : helper)))
    + "\">\n      <option x-text=\"_('Select all')\" value='all_report_select' class='italic'>\n      </option>\n      <option x-text=\"_('Select none')\" value='none_report_select' class='italic'>\n      </option>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"reports") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":6},"end":{"line":82,"column":15}}})) != null ? stack1 : "")
    + "    </optgroup>\n    <optgroup :label='_(\"Models\")' x-show=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"show_report") || (depth0 != null ? lookupProperty(depth0,"show_report") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"show_report","hash":{},"data":data,"loc":{"start":{"line":84,"column":43},"end":{"line":84,"column":58}}}) : helper)))
    + "\">\n      <option x-text=\"_('Select all')\" value='all_models_select' class='italic'>\n      </option>\n      <option x-text=\"_('Select none')\" value='none_models_select' class='italic'>\n      </option>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"models") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":6},"end":{"line":98,"column":15}}})) != null ? stack1 : "")
    + "    </optgroup>\n  </select>\n</div>";
},"useData":true});
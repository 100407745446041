var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n  <select x-data='{ possibilities: "
    + alias4(((helper = (helper = lookupProperty(helpers,"possibilities") || (depth0 != null ? lookupProperty(depth0,"possibilities") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"possibilities","hash":{},"data":data,"loc":{"start":{"line":2,"column":35},"end":{"line":2,"column":52}}}) : helper)))
    + " }' id='new_plot_unit' class='rounded-sm py-0 focus:!ring-elsred focus:!border-elsred'>\n    <template x-for='opt in possibilities'>\n      <option x-text='opt' :value='opt' :selected='opt === \""
    + alias4(((helper = (helper = lookupProperty(helpers,"from") || (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"from","hash":{},"data":data,"loc":{"start":{"line":4,"column":60},"end":{"line":4,"column":68}}}) : helper)))
    + "\"'></option>\n    </template>\n  </select>\n</div>\n<div>\n  <p class='text-sm'>Change the value to set a different initial dimension</p>\n  <input id='initial_dimension_input' type='number' value='"
    + alias4(((helper = (helper = lookupProperty(helpers,"initial_dimension") || (depth0 != null ? lookupProperty(depth0,"initial_dimension") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"initial_dimension","hash":{},"data":data,"loc":{"start":{"line":10,"column":59},"end":{"line":10,"column":80}}}) : helper)))
    + "' :class='get_text_input_classes()' />\n  <span>μm</span>\n</div>";
},"useData":true});
var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./sample_shapes_div.handlebars"),depth0,{"name":"sample_shapes_div","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div x-data='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"x_data") : depth0), depth0))
    + "'>\n  <div class='pt-1 pb-6'>\n    <strong>\n      <span data-cy='report_name'>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"report_name") : depth0), depth0))
    + ", "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"report_instrument") : depth0), depth0))
    + "</span>\n    </strong>\n    <div :id=get_controller('"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conf_uuid") : depth0), depth0))
    + "').uid+\"_container\"></div>\n  </div>\n\n  <div\n    id='update_detected_alert'\n    class='bg-red-100 border border-red-400 text-elsred px-4 py-3 my-3 rounded relative'\n    x-show=\"get_controller('"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conf_uuid") : depth0), depth0))
    + "').show_update_alert()\"\n  >\n    <strong class='font-bold'>Detected remote changes.</strong>\n    <span class='block sm:inline'>Execute the document to update.</span>\n    <button x-text=\"_('Execute')\" :class=\"get_outline_button_classes()\" @click=\"get_controller('"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conf_uuid") : depth0), depth0))
    + "').execute()\"></button>\n    <span class='absolute top-0 bottom-0 right-0 px-4 py-3'>\n      <i :class='get_document_menu_icons_classes()' class='fa-close text-elsred'></i>\n    </span>\n  </div>\n\n  <div id='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conf_uuid") : depth0), depth0))
    + "_tab' class='flex border-b' x-data='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"sections_tab_list") : depth0), depth0))
    + "'>\n    <template x-for='tab in sections_tab_list'>\n      <span class='mr-4'>\n        <button\n          :id='tab.tab_id'\n          x-text='tab.tab_text'\n          type='button'\n          :class=\"{'!text-elsred border-elsred border-b': eval(tab.sec_name), '!text-elsred-light': !eval(tab.sec_name)}\"\n          @click='() => { active_document_tab = tab.tab_text; eval(tab.tab_click) }'\n        ></button>\n      </span>\n    </template>\n  </div>\n  <div id='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conf_uuid") : depth0), depth0))
    + "_tab_content' class='' x-data='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"section_contents_list") : depth0), depth0))
    + "'>\n    <template x-for='section_content in section_contents_list' x-effect='get_controller(\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conf_uuid") : depth0), depth0))
    + "\").document_view_ready()'>\n      <div :id='section_content[0]' x-show='eval(section_content[1])' class='grid grid-cols-1 w-auto striped'>\n        <template x-for='current_option_uuid in section_content[2]'>\n"
    + ((stack1 = container.invokePartial(require("../option/show_option.handlebars"),depth0,{"name":"../option/show_option","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </template>\n      </div>\n    </template>\n  </div>\n  <div id='report_plot_div' class='mt-2 space-y-2'></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"show_images_div") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":2},"end":{"line":47,"column":9}}})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true});
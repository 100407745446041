var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form\n  x-data='{current_conf:{}}'\n  action='javascript:dispatch_event(\"perform-search\"); dispatch_event(\"show-search-results\")'\n>\n  <div\n    id='search_doc'\n    x-data='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"option_uuid_list") : depth0), depth0))
    + "'\n    class='grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 grid-flow-row'\n  >\n    <template\n      x-for='current_option_uuid in option_uuid_list'\n      x-effect='get_controller("
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conf_uuid") : depth0), depth0))
    + ").full_edit()'\n      x-data='{ignore_changes: true}'\n    >\n"
    + ((stack1 = container.invokePartial(require("../option/show_option.handlebars"),depth0,{"name":"../option/show_option","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </template>\n  </div>\n  <div x-init='current_conf = get_controller("
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conf_uuid") : depth0), depth0))
    + "); current_conf.search_elements_created()'>\n    <button\n      id='search_form_btn'\n      :class='get_filled_button_classes()'\n      type='submit'\n      data-cy='apply'\n      x-text='_(\"Apply\")'\n    ></button>\n    <button\n      x-data='{performing_ajax_request_func:true}'\n      id='clear_search_btn'\n      :class='get_filled_button_classes()'\n      @click='fast_search_value=\"\"; reset_queryHash(); clear_url_params(); current_conf.clear_search_doc()'\n      type='button'\n      x-bind:disabled='performing_ajax_request_func'\n      data-cy='reset'\n      x-text='_(\"Clear\")'\n      x-on:ajax-request-finished.window='performing_ajax_request_func = false'\n      x-on:ajax-request-started.window='performing_ajax_request_func = true'\n    ></button>\n    <button\n      id='save_search_btn'\n      type='button'\n      :class='get_filled_button_classes()'\n      @click='save_search_document()'\n      x-text='_(\"Save\")'\n    ></button>\n    <button\n      id='load_search_btn'\n      type='button'\n      :class='get_filled_button_classes()'\n      @click='load_search_document()'\n      x-text='_(\"Load\")'\n    >\n    </button>\n  </div>\n</form>";
},"usePartial":true,"useData":true});
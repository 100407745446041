var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n  id='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":6},"end":{"line":2,"column":12}}}) : helper)))
    + "'\n  role='menu'\n  aria-orientation='horizontal'\n  tabindex='-1'\n  x-show='isOpen'\n  x-transition:enter='transition ease-out duration-100 transform'\n  x-transition:enter-start='opacity-0 scale-95'\n  x-transition:enter-end='opacity-100 scale-100'\n  x-transition:leave='transition ease-in duration-75 transform'\n  x-transition:leave-start='opacity-100 scale-100'\n  x-transition:leave-end='opacity-0 scale-95'\n  class='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-white ring-opacity-5 cursor-pointer'\n></div>";
},"useData":true});
import { remove_double_slashes, remove_first_slash } from '../common/utils';

export async function get_resolution(cam = 'cam2') {
  const response = await post_mdf(`beholder/${cam}/get`, `["resolution"]`);
  return response.result[0];
}

export async function get_cams() {
  const instrument = (await post_mdf('get', '["lastInstrument"]'))?.result[0];
  let all_data = [];
  if (instrument) {
    const devices = (await post_mdf(`${instrument}/get`, '["devices"]')).result[0];

    const cameras = await devices
      .filter((device_array) => device_array[1].includes('beholder'))
      .map((c) => {
        if (c[1][0] === '/') {
          c[1] = c[1].slice(1);
        }
        return c;
      });
    all_data = await Promise.all(
      cameras.map(async (c) => {
        const motor_x = (await post_mdf(`${c[1]}get`, '["motor_x"]')).result[0][0];
        const motor_y = (await post_mdf(`${c[1]}get`, '["motor_y"]')).result[0][0];
        const motor_z = (await post_mdf(`${c[1]}get`, '["motor_z"]')).result[0][0];
        return [
          c[0],
          c[1].replace('beholder/', '').replace('/', ''),
          (await post_mdf(`${c[1]}get`, '["smp0"]')).result[0][0],
          motor_x,
          (await post_mdf(`${motor_x}gete`, '["goingTo"]')).result[0],
          motor_y,
          (await post_mdf(`${motor_y}gete`, '["goingTo"]')).result[0],
          motor_z,
          (await post_mdf(`${motor_z}gete`, '["goingTo"]')).result[0],
        ];
      })
    );
  } else {
    const response = await post_mdf('beholder/list');
    all_data = await response.result[0];
  }

  return [
    await all_data.map((c) => {
      return {
        name: c[0],
        id: c[1],
        sample_url: c[2],
        motors: [
          {
            name: 'x',
            url: remove_first_slash(remove_double_slashes(c[3])),
            min: c[4]?.min,
            max: c[4]?.max,
            current: c[4]?.current,
          },
          {
            name: 'y',
            url: remove_first_slash(remove_double_slashes(c[5])),
            min: c[6]?.min,
            max: c[6]?.max,
            current: c[6]?.current,
          },
          {
            name: 'z',
            url: remove_first_slash(remove_double_slashes(c[7])),
            min: c[8]?.min,
            max: c[8]?.max,
            current: c[8]?.current,
          },
        ],
      };
    }),
    instrument,
  ];
}

async function post_mdf(
  cmd,
  params = '[]',
  server_url = globalThis.get_parameter('server_url') || globalThis.mdf_config.url
) {
  const headers = new Headers();
  headers.append('Authorization', 'Basic YWRtaW46YWRtaW4=');

  const form_data = new FormData();
  form_data.append('request', `{"method":"${cmd}", "params": ${params}}`);

  const requestOptions = {
    method: 'POST',
    headers: headers,
    body: form_data,
    redirect: 'follow',
  };
  const rpc_url = `${server_url}/RPC`;
  try {
    const response = await fetch(rpc_url, requestOptions);
    return response.json();
  } catch (e) {
    if (e.name === 'TypeError' && e.message === 'Failed to fetch') {
      const content_div = document.getElementById('content');
      const https_template = require('../view/https_management.handlebars');
      const https_html = https_template();
      content_div?.insertAdjacentHTML('beforeend', https_html);
    }
  }
}

function get_mdf(server_url, cmd) {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', 'Basic YWRtaW46YWRtaW4=');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  return fetch(`${server_url}/${cmd}`, requestOptions);
}

export async function set_roi(server_url, sample_url, roi) {
  return await post_mdf(`${sample_url}set`, `["roi", [${roi.x}, ${roi.y}, ${roi.width}, ${roi.height}]]`, server_url);
}

export async function get_next_frame(server_url, cam_id, sample_url, instrument, scale = 1) {
  return await post_mdf(`beholder/${cam_id}/new_frame`, '[]', server_url).then(async (response_json) => {
    const frame = response_json.result[0] && response_json.result[0][2];
    if (!frame) {
      return;
    }
    if (sample_url) {
      return await get_mdf(server_url, `stream${sample_url}?opt=frame&scale=${1 / scale}`).then(
        async (response) =>
          await response.blob().then((image) => {
            return { image: image, frame: frame[0].map((v) => v / scale) };
          })
      );
    } else {
      return get_mdf(server_url, `stream/${instrument}/sample0/${cam_id}/?opt=frame&scale=${1 / scale}`).then(
        (response) =>
          response.blob().then((image) => {
            return { image: image, frame: frame[0].map((v) => v / scale) };
          })
      );
    }
  });
}

globalThis.https_management_setup = () => {
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      location.reload();
    }
  });
};

globalThis.move_motor = (url, value) => {
  post_mdf(`${url}/set`, `["goingTo", ${value}]`);
};

var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <textarea :id='current_option.uid + \"_input\"' :name=\"current_option.handle\" class=\"w-[500px] h-[250px]\" :value='current_option.get_input_value()'></textarea>\n  </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <div\n      :id='current_option.uid + \"_input\"'\n      x-init='current_option.set_editor()'\n    ></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n  x-data='{current_option:{}}'\n  x-init='current_option = get_controller(\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"current_option_uuid") : depth0), depth0))
    + "\")'\n  :id='\"edit_\" + current_option.uid'\n>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"plain_text") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":2},"end":{"line":14,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./close_edit_option_buttons.handlebars"),depth0,{"name":"close_edit_option_buttons","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true});
var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n  <div\n    data-cy='document-title'\n    x-data='{ current_option: ()=> {return get_controller(current_option_uuid, \"view/option/show_title.handlebars\")} }'\n    :id='current_option().uid'\n    :data-value='current_option().current'\n    x-show='!current_option().hidden'\n    class='py-1'\n  >\n    <div class='flex'>\n      <div x-html='create_option_html(current_option())'></div>\n      <div x-show='show_version' x-text=\"'&nbsp;' + _('version').toLowerCase() + ' ' + current_version\">\n      </div>\n      <div x-text='\", \" + _(doc_type).toUpperCase()'></div>\n      <div class='pl-1'>\n        <i class='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"doc_icon") || (depth0 != null ? lookupProperty(depth0,"doc_icon") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"doc_icon","hash":{},"data":data,"loc":{"start":{"line":17,"column":18},"end":{"line":17,"column":30}}}) : helper)))
    + " fa-lg text-black'></i>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});
var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n  id='table_edit_overlay'\n  class='h-screen w-screen fixed top-0 left-0 z-30 !bg-neutral-600 opacity-95'\n  x-data='{current_option:{}}'\n  x-init='current_option = get_controller(\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"current_option_uuid") : depth0), depth0))
    + "\")'\n>\n  <h1\n    class='text-center font-bold text-black pb-10'\n    x-text=\"_('Edit property') + ' ' + _('"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"option_name") : depth0), depth0))
    + "') + ' ' + _('of') + ': ' + _('"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title_target") : depth0), depth0))
    + "')\"\n  >\n  </h1>\n  <div\n    class='fixed top-20 z-30 left-[50%] -translate-x-[50%]'\n    x-data='{show_search_elements:current_option.table_to_overlay()}'\n  >\n    <div x-show='show_search_elements'>\n      <button\n        x-data=\"{current_class: 'fa-rotate-right'}\"\n        class='-translate-x-[calc(100%+8px)] translate-y-[63px] bg-white w-[28px] h-[28px] rounded-l'\n        @click='current_class=\"fa-spinner fa-spin pointer-events-none\"; refresh_search_table(\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"current_option_uuid") : depth0), depth0))
    + "\").finally(() => current_class=\"fa-rotate-right\")'\n        data-cy='reload_overlay_search'\n        type='button'\n      >\n        <i class='fa-solid' :class='current_class'></i>\n      </button>\n      <input\n        id='overlay_search_input'\n        type='text'\n        :class='get_text_input_classes()'\n        class='w-full'\n        :placeholder='_(\"find document...\")'\n        @input.debounce='refresh_search_table(\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"current_option_uuid") : depth0), depth0))
    + "\")'\n      />\n    </div>\n    <div id='overlay_content_id' class='flex flex-rows-1 basis-0 flex-wrap lg:flex-nowrap lg:space-x-2'>\n      <div class='overflow-auto lg:w-[600px] border-elsgray-dark border-r' x-show='show_search_elements'>\n        <div id='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"option_handle") : depth0), depth0))
    + "_search_div_container' data-cy='overlay_table_div'></div>\n      </div>\n      <div id='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"option_handle") : depth0), depth0))
    + "_option_container' class='max-w-3xl'>\n"
    + ((stack1 = container.invokePartial(require("../option/edit_option_table.handlebars"),depth0,{"name":"../option/edit_option_table","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n    </div>\n  </div>\n</div>";
},"usePartial":true,"useData":true});
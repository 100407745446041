var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<input\n  id='table_"
    + alias4(((helper = (helper = lookupProperty(helpers,"handle") || (depth0 != null ? lookupProperty(depth0,"handle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"handle","hash":{},"data":data,"loc":{"start":{"line":2,"column":12},"end":{"line":2,"column":22}}}) : helper)))
    + "_input'\n  type='text'\n  class='w-full box-border'\n  :class='get_text_input_classes()'\n  value='"
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":6,"column":9},"end":{"line":6,"column":18}}}) : helper)))
    + "'\n  form='exclude_from_search_form'\n/>";
},"useData":true});
var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"doc_uid") : depth0), depth0))
    + "_div' x-data='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"x_data") : depth0), depth0))
    + "'>\n  <div id='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"doc_uid") : depth0), depth0))
    + "_title' class='pt-1 pb-9'>\n    <strong x-text=\"_('Compare documents')\"></strong>\n  </div>\n  <template x-if='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"show_results") : depth0), depth0))
    + "'>\n    <div class='pb-2 space-y-2'>\n      <div>\n        <select\n          id='fast_compare_selector'\n          class='rounded-sm py-0 focus:!ring-elsred focus:!border-elsred'\n          @change='remove_from_fast_compare($el)'\n          x-init='get_controller(\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conf_uuid") : depth0), depth0))
    + "\").populate_fast_compare_selector($el)'\n        >\n          <option id='fast_compare_default' x-text=\"_('Select a document to remove')\"></option>\n        </select>\n      </div>\n      <div>\n        <span x-text=\"_('Show only different properties')\"></span>\n        <select\n          id='fast_compare_delta'\n          class='rounded-sm py-0 focus:!ring-elsred focus:!border-elsred'\n          @change='update_fast_compare()'\n        >\n          <option value='true' x-text=\"_('True')\"></option>\n          <option value='false' x-text=\"_('False')\" :selected='!get_controller(\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conf_uuid") : depth0), depth0))
    + "\").default_delta'></option>\n        </select>\n      </div>\n    </div>\n  </template>\n  <template x-if='!"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"show_results") : depth0), depth0))
    + "'>\n    <div id='fast_compare_empty_table' x-init='get_controller(\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conf_uuid") : depth0), depth0))
    + "\").show_fast_compare_table()'>\n    </div>\n  </template>\n  <div id='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"doc_uid") : depth0), depth0))
    + "_tab' class='flex border-b' x-show='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"show_results") : depth0), depth0))
    + "' x-data='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"sections_tab_list") : depth0), depth0))
    + "'>\n    <template x-for='tab in sections_tab_list'>\n      <span class='mr-4'>\n        <button\n          :id='tab.tab_id'\n          x-text='tab.tab_text'\n          type='button'\n          :class=\"{'!text-elsred border-elsred border-b': eval(tab.sec_name), '!text-elsred-light': !eval(tab.sec_name)}\"\n          @click='() => { active_document_tab = tab.tab_text; eval(tab.tab_click) }'\n        ></button>\n      </span>\n    </template>\n  </div>\n  <div id='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"doc_uid") : depth0), depth0))
    + "_tab_content' data-objid='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"object_id") : depth0), depth0))
    + "' x-show='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"show_results") : depth0), depth0))
    + "' x-data='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"section_contents_list") : depth0), depth0))
    + "'>\n    <template\n      x-for='section_content in section_contents_list'\n      x-effect='get_controller(\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conf_uuid") : depth0), depth0))
    + "\").document_view_ready()'\n    >\n      <div :id='section_content[0]' x-show='eval(section_content[1])' class='grid grid-cols-1 w-auto striped'>\n        <template x-for='current_option_uuid in section_content[2]'>\n"
    + ((stack1 = container.invokePartial(require("../option/show_option.handlebars"),depth0,{"name":"../option/show_option","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </template>\n      </div>\n    </template>\n  </div>\n</div>";
},"usePartial":true,"useData":true});
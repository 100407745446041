var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<label for='"
    + alias4(((helper = (helper = lookupProperty(helpers,"select_id") || (depth0 != null ? lookupProperty(depth0,"select_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"select_id","hash":{},"data":data,"loc":{"start":{"line":1,"column":12},"end":{"line":1,"column":25}}}) : helper)))
    + "' x-text='_(\"Choose a document to load:\")'></label>\n<select id='"
    + alias4(((helper = (helper = lookupProperty(helpers,"select_id") || (depth0 != null ? lookupProperty(depth0,"select_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"select_id","hash":{},"data":data,"loc":{"start":{"line":2,"column":12},"end":{"line":2,"column":25}}}) : helper)))
    + "' class='rounded-sm py-0 max-w-md' x-data='{options: "
    + alias4(((helper = (helper = lookupProperty(helpers,"options") || (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"options","hash":{},"data":data,"loc":{"start":{"line":2,"column":78},"end":{"line":2,"column":89}}}) : helper)))
    + " }'>\n  <template x-for='opt in options'>\n    <option :value='opt.value' x-text='opt.text'></option>\n  </template>\n</select>";
},"useData":true});
var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div id='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"option_edit") || (depth0 != null ? lookupProperty(depth0,"option_edit") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"option_edit","hash":{},"data":data,"loc":{"start":{"line":8,"column":13},"end":{"line":8,"column":28}}}) : helper)))
    + "_edit_buttons' class='inline'>\n      <button\n        x-text='_(\"Apply\")'\n        :id='\"btn_apply_\"+current_option.uid+\"_edit\"'\n        :class='get_outline_button_classes()'\n        class='scale-75 origin-left'\n        type='button'\n        @click='search_editing=false; current_option.apply_changes()'\n        :data-cy='\"btn_apply_\"+current_option.handle+\"_edit\"'\n      >\n      </button>\n      <button\n        x-text='_(\"Cancel\")'\n        :id='\"btn_cancel_\"+current_option.uid+\"_edit\"'\n        :class='get_outline_button_classes()'\n        class='scale-75 origin-left'\n        type='button'\n        @click='search_editing=false; current_option.restore()'\n        :data-cy='\"btn_cancel_\"+current_option.handle+\"_edit\"'\n      >\n      </button>\n      <button\n        x-text='_(\"Empty table\")'\n        :id='\"btn_empty_\"+current_option.uid+\"_table\"'\n        :class='get_outline_button_classes()'\n        class='scale-75 origin-left'\n        type='button'\n        @click='current_option.empty_currents_table()'\n        :data-cy='\"btn_empty_table_\"+current_option.handle'\n      >\n      </button>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='py-2'>\n  <div\n    :id='\"edit_\"+current_option.uid'\n    x-init='current_option.do_table(true)'\n    :data-cy='current_option.handle + \"_input\"'\n  ></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"edit_buttons") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":2},"end":{"line":40,"column":9}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});
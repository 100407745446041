var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n  x-data='{current_option:{}}'\n  x-init='current_option = get_controller(\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"current_option_uuid") : depth0), depth0))
    + "\")'\n  :id='\"edit_\" + current_option.uid'\n>\n  <input\n    :id='current_option.uid + \"_input\"'\n    :class='get_text_input_classes()'\n    :value='current_option.current'\n    type='text'\n    x-on:keyup.enter='current_option.input_element_enterkey()'\n    :data-cy='current_option.handle + \"_input\"'\n  />\n"
    + ((stack1 = container.invokePartial(require("./close_edit_option_buttons.handlebars"),depth0,{"name":"close_edit_option_buttons","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true});
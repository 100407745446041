var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <span\n    x-data='{current_option:{}}'\n    x-init='current_option = get_controller(\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"current_option_uuid") || (depth0 != null ? lookupProperty(depth0,"current_option_uuid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"current_option_uuid","hash":{},"data":data,"loc":{"start":{"line":4,"column":45},"end":{"line":4,"column":68}}}) : helper)))
    + "\")'\n    :id='current_option.uid+\"_edit_buttons\"'\n    class='inline'\n  >\n    <button\n      x-text='_(\"Apply\")'\n      :id='\"btn_apply_\"+current_option.uid+\"_edit\"'\n      :class='get_outline_button_classes()'\n      class='scale-75 origin-left'\n      type='button'\n      @click='current_option.apply_changes()'\n      :data-cy='\"btn_apply_\"+current_option.handle+\"_edit\"'\n    >\n    </button>\n    <button\n      x-text='_(\"Cancel\")'\n      :id='\"btn_cancel_\"+current_option.uid+\"_edit\"'\n      :class='get_outline_button_classes()'\n      class='scale-75 origin-left'\n      type='button'\n      @click='current_option.restore()'\n      :data-cy='\"btn_cancel_\"+current_option.handle+\"_edit\"'\n    >\n    </button>\n  </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"edit_buttons") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":29,"column":7}}})) != null ? stack1 : "");
},"useData":true});
var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div form='exclude_from_search_form'>\n  <input\n    id='"
    + alias4(((helper = (helper = lookupProperty(helpers,"option_name") || (depth0 != null ? lookupProperty(depth0,"option_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"option_name","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":23}}}) : helper)))
    + "_t_radio'\n    type='radio'\n    name='table_bool_edit'\n    value='true'\n    class='focus:border-elsred checked:!bg-gray-400'\n    :checked='"
    + alias4(((helper = (helper = lookupProperty(helpers,"checked") || (depth0 != null ? lookupProperty(depth0,"checked") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"checked","hash":{},"data":data,"loc":{"start":{"line":8,"column":14},"end":{"line":8,"column":25}}}) : helper)))
    + "'\n  />\n  <label class='form-check-label' for='"
    + alias4(((helper = (helper = lookupProperty(helpers,"option_name") || (depth0 != null ? lookupProperty(depth0,"option_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"option_name","hash":{},"data":data,"loc":{"start":{"line":10,"column":39},"end":{"line":10,"column":54}}}) : helper)))
    + "_t_radio' x-text=\"_('True')\"></label>\n  <input\n    id='"
    + alias4(((helper = (helper = lookupProperty(helpers,"option_name") || (depth0 != null ? lookupProperty(depth0,"option_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"option_name","hash":{},"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":12,"column":23}}}) : helper)))
    + "_f_radio'\n    type='radio'\n    name='table_bool_edit'\n    value='false'\n    class='focus:border-elsred checked:!bg-gray-400'\n    :checked='!"
    + alias4(((helper = (helper = lookupProperty(helpers,"checked") || (depth0 != null ? lookupProperty(depth0,"checked") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"checked","hash":{},"data":data,"loc":{"start":{"line":17,"column":15},"end":{"line":17,"column":26}}}) : helper)))
    + "'\n  />\n  <label class='form-check-label' for='"
    + alias4(((helper = (helper = lookupProperty(helpers,"option_name") || (depth0 != null ? lookupProperty(depth0,"option_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"option_name","hash":{},"data":data,"loc":{"start":{"line":19,"column":39},"end":{"line":19,"column":54}}}) : helper)))
    + "_f_radio' x-text=\"_('False')\"></label>\n</div>";
},"useData":true});
var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n  x-data='{current_option:{}}'\n  x-init='current_option = get_controller(\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"current_option_uuid") || (depth0 != null ? lookupProperty(depth0,"current_option_uuid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"current_option_uuid","hash":{},"data":data,"loc":{"start":{"line":3,"column":43},"end":{"line":3,"column":66}}}) : helper)))
    + "\")'\n  :id='\"edit_\" + current_option.uid'\n>\n  <p class='table-cell cursor-pointer pr-6 pb-2'>\n    <img :src='\"data:image/jpeg;base64,\" + current_option.style.image' class='inline-block pb-2' />\n    <i\n      @click='current_option.recaptcha()'\n      class='fa-solid fa-rotate-right cursor-pointer inline-block align-bottom h-full pb-2'\n    ></i>\n    <div>\n      <input\n        :id='current_option.uid + \"_captcha_input\"'\n        :class='get_text_input_classes()'\n        class='invalid:bg-amber-100'\n        type='text'\n        placeholder='Insert captcha'\n        x-bind:required='current_option.is_required'\n      />\n    </div>\n  </p>\n</div>";
},"useData":true});
var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <input\n          :id='\"confirm_\" + option.option_handle + \"_edit\"'\n          name='edited_option_checked'\n          type='checkbox'\n          class='rounded-sm border-elsred text-elsred focus:ring-elsred' \n          @click='$dispatch(\"option_checkbox_click\")'\n          checked\n        />\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div x-data='"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"options_list") || (depth0 != null ? lookupProperty(depth0,"options_list") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"options_list","hash":{},"data":data,"loc":{"start":{"line":1,"column":13},"end":{"line":1,"column":29}}}) : helper)))
    + "'>\n  <template x-for='option in options_list'>\n    <div>\n      <div x-text='option.option_name'></div>\n      <span x-show='option.current_value || [0, \"\", false].includes(option.current_value)'>\n        <b x-text=\"_('Now') + ':'\"></b>\n        <span x-text='option.current_value'></span>\n      </span>\n      <span x-show='option.original_value || [0, \"\", false].includes(option.original_value)'>\n        <b x-text=\"_('Before') + ':'\"></b>\n        <span x-text='option.original_value'></span>\n      </span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"checkbox") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":6},"end":{"line":22,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n  </template>\n</div>";
},"useData":true});
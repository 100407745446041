var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div x-data='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"options") : depth0), depth0))
    + "'>\n  <h1 x-text=\"_('Remote support instructions')\"></h1>\n  Your support request has been received. \n  In order to allow an operator to remotely connect to your computer, please follow these steps:\n  <h2>Getting support in Windows</h2>\n  <ol class=\"!list-decimal list-inside p-1 m-1\">\n    <li>Ensure the computer is connected to the internet and has access to the following website: <a href=\"https://dwservice.net\">dwservice.net</a></li>\n    <li>If DWAgent is not installed, start the installer for the application DWAgent from the above mentioned website</li>\n    <li>Wait for the technical support to provide an INSTALLATION CODE.</li>\n  </ol>\n  <h2>Getting support in Linux</h2>\n  <ol class=\"!list-decimal list-inside p-1 m-1\">\n    <li>Ensure the computer is connected to the internet and has access to the following website: <a href=\"https://serveo.net\">serveo.net</a></li>\n    <li>If the application DWAgent is installed, open the DWAgent monitor panel. You should find the DWAgent application under the main operative system start menu (K), by searching the name DWAgent (just start typing).</li>\n    <li>Open a terminal application. You should find the terminal application under the main operative system start menu (K), by searching the name Konsole or terminal (just start typing).</li>\n    <li>Copy and paste the text in the below text box \"Shell Command\" into the terminal. Do NOT use Ctrl+V to paste: use Right+Click->Paste to paste the command into the terminal.</li>\n    <li>Press Enter to execute the Shell Command. The support service will then be able to connect to your computer.</li>\n  </ol>\n  <template\n    x-for='current_option_uuid in options_list'\n    x-effect='get_controller(\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conf_uuid") : depth0), depth0))
    + "\").document_view_ready()'\n  >\n"
    + ((stack1 = container.invokePartial(require("./option/show_option.handlebars"),depth0,{"name":"./option/show_option","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </template>\n</div>";
},"usePartial":true,"useData":true});
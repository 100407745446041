var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div id='admin-li' class='relative' x-data='{ isOpen: false }'>\n"
    + ((stack1 = container.invokePartial(require("./admin_menu.handlebars"),depth0,{"name":"admin_menu","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n<div id='languages-li' class='relative' x-data='{ isOpen: false }'>\n"
    + ((stack1 = container.invokePartial(require("./languages_menu.handlebars"),depth0,{"name":"languages_menu","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n<div id='databases-li' class='relative' x-data='{ isOpen: false }'>\n</div>\n<div id='logout-li' class='relative' x-data='{ isOpen: false }'>\n"
    + ((stack1 = container.invokePartial(require("./logout_menu.handlebars"),depth0,{"name":"logout_menu","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true});
import { sleep } from '../common/utils';
import { get_next_frame, set_roi } from './mdf_rpc';

const cams_to_stop = {};
const count_of_concurrent_loops = {};

self.onmessage = async (message) => {
  if (message.data.stop) {
    cams_to_stop[message.data.cam_id] = true;
    return;
  }

  if (message.data.selection_box) {
    await set_roi(
      message.data.server_url,
      message.data.sample_url[0] === '/' ? message.data.sample_url.slice(1) : message.data.sample_url,
      message.data.selection_box
    );
  } else {
    count_of_concurrent_loops[message.data.cam_id] ||= 0;
    count_of_concurrent_loops[message.data.cam_id]++;
    const one_concurrent_loop_per_cam = count_of_concurrent_loops[message.data.cam_id] <= 1;
    while (!cams_to_stop[message.data.cam_id] && one_concurrent_loop_per_cam) {
      self.postMessage(
        await get_next_frame(
          message.data.server_url,
          message.data.cam_id,
          message.data.sample_url,
          message.data.instrument,
          message.data.scale
        )
      );
      await sleep(100);
    }
    cams_to_stop[message.data.cam_id] = false;
    count_of_concurrent_loops[message.data.cam_id]--;
  }
};

var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='flex flex-wrap flex-row justify-center align-middle'>\n  <form\n    id='support_request_form'\n    x-data='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"options") : depth0), depth0))
    + "'\n    action='javascript:dispatch_event(\"send_support_request_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conf_uuid") : depth0), depth0))
    + "\")'\n    class='w-1/2'\n  >\n    <h1 x-text=\"_('Remote support request')\"></h1>\n    <template\n      x-for='current_option_uuid in options_list'\n      x-effect='get_controller(\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conf_uuid") : depth0), depth0))
    + "\").document_view_ready()'\n    >\n"
    + ((stack1 = container.invokePartial(require("./option/show_option.handlebars"),depth0,{"name":"./option/show_option","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </template>\n    <div class='flex gap-x-2 pt-6'>\n      <button x-text=\"_('Confirm')\" type='submit' :class='get_outline_button_classes()'></button>\n    </div>\n  </form>\n</div>";
},"usePartial":true,"useData":true});
var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div x-data='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"x_data") : depth0), depth0))
    + "'>\n  <h1 x-text=\"'"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"usercompany") : depth0), depth0))
    + " - ' + _('Details')\" data-cy='usercompany_name'></h1>\n  <div\n    id='usercompanyTab'\n    class='flex border-b pt-4'\n    x-data='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"sections_tab_list") : depth0), depth0))
    + "'\n  >\n    <template x-for='tab in sections_tab_list'>\n      <span class='mr-4'>\n        <button\n          :id='tab.tab_id'\n          x-text='tab.tab_text'\n          type='button'\n          :class=\"{'!text-elsred border-elsred border-b': eval(tab.sec_name), '!text-elsred-light': !eval(tab.sec_name)}\"\n          @click='() => { active_document_tab = tab.tab_text; eval(tab.tab_click) }'\n        ></button>\n      </span>\n    </template>\n  </div>\n  <div id='usercompanyTabContent' x-data='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"section_contents_list") : depth0), depth0))
    + "'>\n    <template\n      x-for='section_content in section_contents_list'\n      x-effect='get_controller(\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conf_uuid") : depth0), depth0))
    + "\").document_view_ready()'\n    >\n      <div\n        :id='section_content[0]'\n        x-show='eval(section_content[1])'\n        class='striped'\n      >\n        <template x-for='current_option_uuid in section_content[2]'>\n"
    + ((stack1 = container.invokePartial(require("../option/show_option.handlebars"),depth0,{"name":"../option/show_option","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </template>\n      </div>\n    </template>\n  </div>\n</div>";
},"usePartial":true,"useData":true});
var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n  <label\n    for='"
    + alias4(((helper = (helper = lookupProperty(helpers,"name_input") || (depth0 != null ? lookupProperty(depth0,"name_input") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name_input","hash":{},"data":data,"loc":{"start":{"line":3,"column":9},"end":{"line":3,"column":23}}}) : helper)))
    + "'\n    class='block text-sm font-medium text-gray-700'\n    x-text='_(\"Document name:\")'\n  ></label>\n  <div class='relative mt-1 rounded-md'>\n    <input\n      id='"
    + alias4(((helper = (helper = lookupProperty(helpers,"name_input") || (depth0 != null ? lookupProperty(depth0,"name_input") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name_input","hash":{},"data":data,"loc":{"start":{"line":9,"column":10},"end":{"line":9,"column":24}}}) : helper)))
    + "'\n      :class='get_text_input_classes()'\n      class='block w-full'\n      type='text'\n      :placeholder='_(\"name\")'\n    />\n  </div>\n  <label\n    for='"
    + alias4(((helper = (helper = lookupProperty(helpers,"comment_input") || (depth0 != null ? lookupProperty(depth0,"comment_input") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"comment_input","hash":{},"data":data,"loc":{"start":{"line":17,"column":9},"end":{"line":17,"column":26}}}) : helper)))
    + "'\n    class='block text-sm font-medium text-gray-700'\n    x-text='_(\"Document comment:\")'\n  ></label>\n  <div class='relative mt-1 rounded-md'>\n    <input\n      type='text'\n      id='"
    + alias4(((helper = (helper = lookupProperty(helpers,"comment_input") || (depth0 != null ? lookupProperty(depth0,"comment_input") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"comment_input","hash":{},"data":data,"loc":{"start":{"line":24,"column":10},"end":{"line":24,"column":27}}}) : helper)))
    + "'\n      :placeholder='_(\"comment\")'\n      :class='get_text_input_classes()'\n      class='block w-full'\n    />\n  </div>\n</div>";
},"useData":true});
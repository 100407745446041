var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n  id='table_view_overlay'\n  class='h-screen w-screen fixed top-0 left-0 z-30 !bg-neutral-600 opacity-95'\n>\n  <div\n    class='p-4'\n    x-data='{current_option:{}}'\n    x-init='current_option = get_controller(\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"current_option_uuid") || (depth0 != null ? lookupProperty(depth0,"current_option_uuid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"current_option_uuid","hash":{},"data":data,"loc":{"start":{"line":8,"column":45},"end":{"line":8,"column":68}}}) : helper)))
    + "\")'\n  >\n    <div class='text-right'>\n      <i\n        class='fa-lg fa-solid fa-compress text-white cursor-pointer'\n        @click='close_view_table_overlay()'\n      ></i>\n    </div>\n    <div class='text-left pb-2'>\n      <h1\n        class='font-bold text-white'\n        x-text='current_option.table_to_overlay_title()'\n      ></h1>\n    </div>\n    <div\n      :id='\"view_\"+current_option.uid'\n      x-init='current_option.do_table(false, false, true)'\n    ></div>\n  </div>\n</div>";
},"useData":true});
var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <option x-text=\"_('"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0))
    + "')\" value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "' :selected='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"opt_selected") : depth0), depth0))
    + "'>\n          </option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n  x-data='{current_option:{}}'\n  x-init='current_option = get_controller(\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"current_option_uuid") : depth0), depth0))
    + "\")'\n  :id='\"edit_\" + current_option.uid'\n>\n  <div x-data='{override_auto: !current_option.style.auto || current_option.style.auto !== \"auto\"}'>\n      <div :id='current_option.uid + \"_auto_calc\"' x-show='current_option.autocalc_option'>\n        <div x-data='' x-init='$watch(\"override_auto\", override_auto => {\n                                                                    current_option.style.auto = override_auto ? \"override\" : \"auto\";\n                                                                    current_option.conf.log.modify_style({ auto: current_option.style.auto }, current_option.handle);\n                                                                    if(!override_auto) {\n                                                                        current_option.reset_default()\n                                                                    }\n                                                                 })'>\n          <input type='checkbox' :id='current_option.uid + \"_user_current\"' x-model=\"override_auto\" :data-cy='current_option.handle + \"_override\"'/>\n          <label x-text=\"_('Override calculated value')\" :for='current_option.uid + \"_user_current\"'></label>\n        </div>\n      </div>\n      <select\n        :id='current_option.uid + \"_input\"'\n        class='text-black py-0 focus:!ring-elsred focus:!border-elsred'\n        :data-cy='current_option.handle+\"_input\"'\n        x-on:change='current_option.chooser_value_changed($el)'\n        x-on:input.change='!ignore_changes && current_option.update_history()'\n        :disabled=\"!override_auto || current_option.display_disabled(current_option.handle)\"\n      >\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"options") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":8},"end":{"line":30,"column":17}}})) != null ? stack1 : "")
    + "      </select>\n"
    + ((stack1 = container.invokePartial(require("./close_edit_option_buttons.handlebars"),depth0,{"name":"close_edit_option_buttons","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n</div>";
},"usePartial":true,"useData":true});
var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id='forgot-pw_div' class='pt-10 justify-items-center grid gap-y-1'>\n  <h2 x-text=\"_('Forgot password')\" class='text-elsred pt-2 pb-8'></h2>\n  <form class='grid gap-y-1 w-[600px]' action='javascript:confirm_password_reset(\""
    + alias4(((helper = (helper = lookupProperty(helpers,"shown_before") || (depth0 != null ? lookupProperty(depth0,"shown_before") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shown_before","hash":{},"data":data,"loc":{"start":{"line":3,"column":82},"end":{"line":3,"column":98}}}) : helper)))
    + "\")'>\n    <div class='flex flex-nowrap'>\n      <label x-text=\"_('Email address')\" class='w-[200px]' for='forgot_password_email'></label>\n      <input\n        id='forgot_password_email'\n        type='email'\n        autocomplete='off'\n        placeholder='yourname@yourcompany.com'\n        class='rounded-sm border invalid:bg-amber-100 grow'\n        required\n      />\n    </div>\n    <div class='flex flex-nowrap'>\n      <label x-text=\"_('New password')\" class='w-[200px]' for='forgot_password_new_pw'></label>\n      <input\n        id='forgot_password_new_pw'\n        type='password'\n        autocomplete='new-password'\n        placeholder='Username@00'\n        class='rounded-sm border invalid:bg-amber-100 grow'\n        pattern='^(?=.*[0-9])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,}$'\n        x-on:invalid=\"$el.setCustomValidity('Invalid password, must be at least 8 characters long and contain an uppercase and a number')\"\n        required\n      />\n    </div>\n    <div class='flex flex-nowrap'>\n      <label x-text=\"_('Confirm new password')\" class='w-[200px]' for='forgot_password_confirm_pw'></label>\n      <input\n        id='forgot_password_confirm_pw'\n        type='password'\n        autocomplete='new-password'\n        class='rounded-sm border invalid:bg-amber-100 grow'\n        required\n      />\n    </div>\n    <div class='flex gap-2 p-2'>\n      <button\n        class='grow py-2 px-6 text-white rounded-sm bg-elsred focus:outline-none focus:ring-2 focus:!ring-elsred focus:ring-offset-2'\n        type='submit'\n        data-cy='reset-password'\n        x-text='_(\"Update password\")'\n      ></button>\n      <button\n        class='grow cursor-pointer text-elsred hover:text-elsred-dark border py-2 px-8'\n        type='button'\n        @click='cancel_new_user(\""
    + alias4(((helper = (helper = lookupProperty(helpers,"shown_before") || (depth0 != null ? lookupProperty(depth0,"shown_before") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shown_before","hash":{},"data":data,"loc":{"start":{"line":48,"column":33},"end":{"line":48,"column":49}}}) : helper)))
    + "\")'\n        x-text='_(\"Cancel\")'\n      ></button>\n    </div>\n  </form>\n</div>";
},"useData":true});
var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id='sign-in_div' class='min-h-full justify-center py-4 flex'>\n  <div class='pt-12 text-center'>\n    <h2 class='text-elsred' x-text='_(\"Register new user\")'></h2>\n    <form x-data='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"options") : depth0), depth0))
    + "' class='mt-6' action='javascript:confirm_new_user(\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"shown_before") : depth0), depth0))
    + "\")'>\n      <template x-for='current_option_uuid in options_list'>\n"
    + ((stack1 = container.invokePartial(require("./new_user_form_option.handlebars"),depth0,{"name":"new_user_form_option","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </template>\n      <div class=\"flex gap-x-2 pt-6\">\n          <button :class='get_filled_button_classes()' type='submit' x-text='_(\"Register\")'></button>\n          <button :class='get_outline_button_classes()' type='reset' x-text='_(\"Reset value\")'></button>\n          <button\n            :class='get_outline_button_classes()'\n            type='button'\n            @click='cancel_new_user(\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"shown_before") : depth0), depth0))
    + "\")'\n            x-text='_(\"Cancel\")'\n          ></button>\n      </div>\n    </form>\n  </div>\n</div>";
},"usePartial":true,"useData":true});
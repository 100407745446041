var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id='"
    + alias4(((helper = (helper = lookupProperty(helpers,"target") || (depth0 != null ? lookupProperty(depth0,"target") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"target","hash":{},"data":data,"loc":{"start":{"line":1,"column":9},"end":{"line":1,"column":19}}}) : helper)))
    + "_shapes_cards"
    + alias4(((helper = (helper = lookupProperty(helpers,"smp_idx") || (depth0 != null ? lookupProperty(depth0,"smp_idx") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"smp_idx","hash":{},"data":data,"loc":{"start":{"line":1,"column":32},"end":{"line":1,"column":43}}}) : helper)))
    + "' x-data='{ samples: "
    + alias4(((helper = (helper = lookupProperty(helpers,"samples") || (depth0 != null ? lookupProperty(depth0,"samples") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"samples","hash":{},"data":data,"loc":{"start":{"line":1,"column":64},"end":{"line":1,"column":75}}}) : helper)))
    + " }'>\n  <h5 x-text=\"_('SAMPLE')+', '+'"
    + alias4(((helper = (helper = lookupProperty(helpers,"smp_name") || (depth0 != null ? lookupProperty(depth0,"smp_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"smp_name","hash":{},"data":data,"loc":{"start":{"line":2,"column":32},"end":{"line":2,"column":44}}}) : helper)))
    + "'\" class='font-bold clear-both mt-2'></h5>\n  <template x-for='smp in samples' x-effect=\"$dispatch('sample_shapes_container_ready', { target:'"
    + alias4(((helper = (helper = lookupProperty(helpers,"target") || (depth0 != null ? lookupProperty(depth0,"target") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"target","hash":{},"data":data,"loc":{"start":{"line":3,"column":98},"end":{"line":3,"column":108}}}) : helper)))
    + "' })\">\n    <div class='my-2.5 mr-4 min-w-min font-bold inline float-left border-black' style='width:"
    + alias4(((helper = (helper = lookupProperty(helpers,"width") || (depth0 != null ? lookupProperty(depth0,"width") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"width","hash":{},"data":data,"loc":{"start":{"line":4,"column":93},"end":{"line":4,"column":102}}}) : helper)))
    + "px'>\n      <div :id='`"
    + alias4(((helper = (helper = lookupProperty(helpers,"target") || (depth0 != null ? lookupProperty(depth0,"target") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"target","hash":{},"data":data,"loc":{"start":{"line":5,"column":17},"end":{"line":5,"column":27}}}) : helper)))
    + "_cnv_"
    + alias4(((helper = (helper = lookupProperty(helpers,"smp_idx") || (depth0 != null ? lookupProperty(depth0,"smp_idx") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"smp_idx","hash":{},"data":data,"loc":{"start":{"line":5,"column":32},"end":{"line":5,"column":43}}}) : helper)))
    + "_${smp.idx}_body`' class='inline float-left w-full'>\n        <div x-text='`${smp.title}`' class='text-black'></div>\n        <div :id='`"
    + alias4(((helper = (helper = lookupProperty(helpers,"target") || (depth0 != null ? lookupProperty(depth0,"target") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"target","hash":{},"data":data,"loc":{"start":{"line":7,"column":19},"end":{"line":7,"column":29}}}) : helper)))
    + "_canvas_"
    + alias4(((helper = (helper = lookupProperty(helpers,"smp_idx") || (depth0 != null ? lookupProperty(depth0,"smp_idx") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"smp_idx","hash":{},"data":data,"loc":{"start":{"line":7,"column":37},"end":{"line":7,"column":48}}}) : helper)))
    + "_${smp.idx}_temp`' class='whitespace-nowrap' class='text-black' x-text='`${smp.temp}`'></div>\n        <canvas :id='`"
    + alias4(((helper = (helper = lookupProperty(helpers,"target") || (depth0 != null ? lookupProperty(depth0,"target") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"target","hash":{},"data":data,"loc":{"start":{"line":8,"column":22},"end":{"line":8,"column":32}}}) : helper)))
    + "_canvas_"
    + alias4(((helper = (helper = lookupProperty(helpers,"smp_idx") || (depth0 != null ? lookupProperty(depth0,"smp_idx") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"smp_idx","hash":{},"data":data,"loc":{"start":{"line":8,"column":40},"end":{"line":8,"column":51}}}) : helper)))
    + "_${smp.idx}`' class='border-2 border-solid border-black w-full'></canvas>\n      </div>\n    </div>\n  </template>\n</div>";
},"useData":true});
var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class='px-2 sm:px-6 lg:px-8'>\n"
    + ((stack1 = container.invokePartial(require("./navbar_anchors.handlebars"),depth0,{"name":"navbar_anchors","hash":{"doc_type":"ModelPlot","button_text":"Overlay"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./navbar_anchors.handlebars"),depth0,{"name":"navbar_anchors","hash":{"doc_type":"ModelQuartz","button_text":"Quartz"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./navbar_anchors.handlebars"),depth0,{"name":"navbar_anchors","hash":{"doc_type":"Compare","button_text":"Compare"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true});
var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id='model_settings_div' x-data='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"x_data") : depth0), depth0))
    + "'>\n  <div id='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"doc_uid") : depth0), depth0))
    + "_title' class='pt-1 pb-9'>\n    <strong x-data='{ current_option_uuid: \""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"doc_name_uuid") : depth0), depth0))
    + "\", doc_type: \""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"doc_type") : depth0), depth0))
    + "\" }'>\n      <div x-show='current_option_uuid'>\n"
    + ((stack1 = container.invokePartial(require("../option/show_title.handlebars"),depth0,{"name":"../option/show_title","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n    </strong>\n    <div data-cy='document-sub-title' class='text-xs' x-data='{ current_option_uuid: \""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"doc_comment_uuid") : depth0), depth0))
    + "\" }'>\n      <div x-show='current_option_uuid'>\n"
    + ((stack1 = container.invokePartial(require("../option/show_subtitle.handlebars"),depth0,{"name":"../option/show_subtitle","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n    </div>\n  </div>\n  <div class='grid grid-cols-2'>\n    <div id='model_left_div' class='pr-7 col-span-1'>\n      <div id='model_lTab' class='flex border-b' x-data='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"sections_tab_list") : depth0), depth0))
    + "'>\n        <template x-for='tab in left_sections_tab_list'>\n          <span class='mr-4'>\n            <button\n              :id='tab.tab_id'\n              x-text='tab.tab_text'\n              type='button'\n              :class=\"{'!text-elsred border-elsred border-b': eval(tab.sec_name), '!text-elsred-light': !eval(tab.sec_name)}\"\n              @click='() => { active_document_tab = tab.tab_text; eval(tab.tab_click); get_controller(\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conf_uuid") : depth0), depth0))
    + "\").left_active_section = tab.sec_name }'\n            ></button>\n          </span>\n        </template>\n      </div>\n      <div id='model_lContent' data-objid='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"object_id") : depth0), depth0))
    + "' x-data='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"section_contents_list") : depth0), depth0))
    + "'>\n        <template\n          x-for='section_content in left_section_contents_list'\n          x-effect='get_controller(\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conf_uuid") : depth0), depth0))
    + "\").document_view_ready()'\n        >\n          <div :id='section_content[0]' x-show='eval(section_content[1])' class='grid grid-cols-1 w-auto striped'>\n            <template x-for='current_option_uuid in section_content[2]'>\n"
    + ((stack1 = container.invokePartial(require("../option/show_option.handlebars"),depth0,{"name":"../option/show_option","data":data,"indent":"              ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </template>\n          </div>\n        </template>\n      </div>\n    </div>\n    <div id='model_right_div' class='col-span-1' x-data='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"sections_tab_list") : depth0), depth0))
    + "' x-show='show_right_sections'>\n      <div id='model_rTab' class='flex border-b'>\n        <template x-for='tab in right_sections_tab_list'>\n          <span class='mr-4'>\n            <button\n              :id='tab.tab_id'\n              x-text='tab.tab_text'\n              type='button'\n              :class=\"{'!text-elsred border-elsred border-b': eval(tab.sec_name), '!text-elsred-light': !eval(tab.sec_name)}\"\n              @click='() => { active_document_tab = tab.tab_text; eval(tab.tab_click); get_controller(\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conf_uuid") : depth0), depth0))
    + "\").right_active_section = tab.tab_text }'\n            ></button>\n          </span>\n        </template>\n      </div>\n      <div id='model_rContent' class='w-full' data-objid='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"object_id") : depth0), depth0))
    + "' x-data='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"section_contents_list") : depth0), depth0))
    + "'>\n        <template\n          x-for='section_content in right_section_contents_list'\n          x-effect='get_controller(\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conf_uuid") : depth0), depth0))
    + "\").document_view_ready()'\n        >\n          <div :id='section_content[0]' x-show='eval(section_content[1])' class='grid grid-cols-1 w-auto striped'>\n            <template x-for='current_option_uuid in section_content[2]'>\n"
    + ((stack1 = container.invokePartial(require("../option/show_option.handlebars"),depth0,{"name":"../option/show_option","data":data,"indent":"              ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </template>\n          </div>\n        </template>\n      </div>\n    </div>\n  </div>\n  <div id='model_plot_div' class='mt-2 space-y-2'></div>\n</div>";
},"usePartial":true,"useData":true});
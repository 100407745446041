var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n  x-data='{current_option:{}}'\n  x-init='current_option = get_controller(\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"current_option_uuid") : depth0), depth0))
    + "\")'\n  :id='\"edit_\" + current_option.uid'\n>\n  <span :id='current_option.uid + \"_input\"'>\n    <input\n      x-on:input.change='!ignore_changes && current_option.update_history()'\n      :id='\"input_true_\"+current_option.handle'\n      :class='get_radio_buttons_classes()'\n      type='radio'\n      :name='\"Bool_\"+current_option.handle'\n      value='true'\n      :checked='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"checked_true") : depth0), depth0))
    + "'\n      :data-cy=`${current_option.conf.class_name}_${current_option.handle}_true`\n    />\n    <label\n      :for='\"input_true_\"+current_option.handle'\n      x-text='current_option.get_option_labels(true)'\n    ></label>\n    <input\n      x-on:input.change='!ignore_changes && current_option.update_history()'\n      :id='\"input_false_\"+current_option.handle'\n      :class='get_radio_buttons_classes()'\n      type='radio'\n      :name='\"Bool_\"+current_option.handle'\n      value='false'\n      :checked='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"checked_false") : depth0), depth0))
    + "'\n      :data-cy=`${current_option.conf.class_name}_${current_option.handle}_false`\n    />\n    <label\n      :for='\"input_false_\"+current_option.handle'\n      x-text='current_option.get_option_labels(false)'\n    ></label>\n  </span>\n"
    + ((stack1 = container.invokePartial(require("./close_edit_option_buttons.handlebars"),depth0,{"name":"close_edit_option_buttons","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true});
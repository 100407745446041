var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n  x-data='{current_option:{}}'\n  x-init='current_option = get_controller(\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"current_option_uuid") : depth0), depth0))
    + "\")'\n  :id='\"edit_\" + current_option.uid'\n  class='w-full pl-1'\n>\n  <input\n    :placeholder='_(\"Type to search\")+\"...\"'\n    :id='current_option.uid + \"_input\"'\n    :class='get_text_input_classes()'\n    x-on:input.change='!ignore_changes && current_option.update_history()'\n    x-on:change='!ignore_changes && current_option.update_history()'\n    class='w-full'\n    :value='current_option.get_input_value()'\n    type='text'\n    x-on:keyup.enter='current_option.input_element_enterkey()'\n    :data-cy='current_option.handle + \"_input\"'\n    @input.debounce='current_option.search_table_for_role_option($el.value, current_option.style.target)'\n    x-init='$el.focus()'\n  />\n"
    + ((stack1 = container.invokePartial(require("./close_edit_option_buttons.handlebars"),depth0,{"name":"close_edit_option_buttons","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  <button\n    x-text=\"_('Empty current')\"\n    :class='get_outline_button_classes()'\n    class='scale-75 origin-left'\n    type='button'\n    @click='current_option.reset_default(); current_option.apply_changes()'\n    :data-cy='\"btn_reset_\" + current_option.handle + \"_edit\"'\n  ></button>\n</div>";
},"usePartial":true,"useData":true});
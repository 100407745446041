var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id='pie_plot_overlay' class='h-full w-full top-0 left-0 z-30 bg-neutral-600 opacity-95 fixed text-center'>\n  <button\n    @click='remove_element(\"pie_plot_overlay\")'\n    class='p-4 right-10 text-6xl text-gray-400 hover:text-white absolute fa-solid fa-xmark'\n    type='button'\n  >\n  </button>\n  <h1\n    class='mt-8 font-bold text-black'\n    x-text=\"_('"
    + alias4(((helper = (helper = lookupProperty(helpers,"option_name") || (depth0 != null ? lookupProperty(depth0,"option_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"option_name","hash":{},"data":data,"loc":{"start":{"line":10,"column":15},"end":{"line":10,"column":30}}}) : helper)))
    + "') + ', ' + _('plot for column') + ' ' + _('"
    + alias4(((helper = (helper = lookupProperty(helpers,"column") || (depth0 != null ? lookupProperty(depth0,"column") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"column","hash":{},"data":data,"loc":{"start":{"line":10,"column":74},"end":{"line":10,"column":84}}}) : helper)))
    + "')\"\n  ></h1>\n  <div id='plot_container' class='p-6 w-full h-5/6'></div>\n</div>";
},"useData":true});